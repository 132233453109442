<template>
  <va-card class="row align--center mx-2">
    <va-list class="mx-4">
      <va-list-label> Chats </va-list-label>
      <va-inner-loading :loading="loading">
        <va-list-item
          class="my-4"
          style="max-width: 95%"
          v-for="(myChat, index) in myChats"
          :key="index"
          fit
          @click="openChat(myChat.id)"
        >
          <va-list-item-section avatar>
            <va-avatar>
              <img :src="$store.state.imgUrl + myChat.logo" />
            </va-avatar>
          </va-list-item-section>

          <va-list-item-section>
            <va-list-item-label class="py-3">
              <strong>{{ myChat.name }}</strong>
            </va-list-item-label>

            <va-list-item-label class="py-3" v-if="myChat.unread">
              {{ $t("new_messages") }}
            </va-list-item-label>

            <va-list-separator
              fit
              spaced
              style="font-size: 14px; font-weight: 700"
            />
          </va-list-item-section>
          <va-list-item-section icon v-if="myChat.unread">
            <va-button color="primary" gradient class="mr-4 no-padding">{{
              myChat.unread
            }}</va-button>
          </va-list-item-section>
        </va-list-item>
      </va-inner-loading>
    </va-list>
  </va-card>
</template>

<script>
import { gql, request } from "graphql-request";
export default {
  data() {
    return {
      myChats: [],
      myChatsCount: null,
      user_id: JSON.parse(localStorage["userData"])?.id,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: false,
    };
  },
  async mounted() {
    // Pagination Logic
    try {
      this.loading = true;
      const MY_CHATS_COUNT = gql`
        query ($id: Int) {
          myChats: ChampEditorsCount(where: { user_id: $id }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        MY_CHATS_COUNT,
        { id: this.user_id }
      );
      this.myChatsCount = counterResponse.myChats.count;
      this.pages = Math.ceil(this.myChatsCount / this.limit);
      this.getMyChampionships();
    } catch (error) {
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getMyChampionships() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const MY_CHATS = gql`
        query ($id: Int) {
          unReadChampMessages(id: $id) {
            id
            name
            logo
            unread
          }
        }
      `;
      try {
        const response = await request(this.$store.state.appUrl, MY_CHATS, {
          id: this.user_id,
        });
        this.myChats = response.unReadChampMessages;
        console.log(this.myChats);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },

    async openChat(champ_id) {
      const READ_CHAMP_MESSAGES = gql`
        mutation ($id: Int, $champ_id: Int) {
          readChampMessages(id: $id, champ_id: $champ_id) {
            status
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        READ_CHAMP_MESSAGES,
        {
          id: this.user_id,
          champ_id,
        }
      );
      if (response.readChampMessages.status) {
        const UNREAD_MESSAGES = gql`
          query ($id: Int) {
            ChatReceiversCount(
              where: { receiver_id: $id, message_read: false }
            ) {
              count
            }
          }
        `;
        const response = await request(
          this.$store.state.appUrl,
          UNREAD_MESSAGES,
          { id: this.user_id }
        );
        this.$store.commit(
          "initNewMessages",
          response.ChatReceiversCount.count
        );
        this.$router.push({
          name: "chat",
          params: { id: champ_id },
        });
      }
    },
  },
  watch: {
    async currentPage() {
      await this.getMyChampionships();
    },
  },
};
</script>

<style>
button.no-padding > .va-button__content {
  padding: 0 !important;
}
</style>
